import React from "react";
import { Box, MaxWidth, Text, Inline, Flex } from "@sqymagma/elements";
import {
  isoCountryCodes,
  isoCCAACodes,
  isoProvinceCodes,
} from "../data/apiTableData";
import { Github, Bitbucket, AccessApi } from "../components/IconSystem";
import { theme } from "../utils/themeGet";
import ModDashedLine from "../components/Chronology/Modules/ModDashedLine";
import { Paragraph } from "../components/Textstyles/Styles";

import BackgroundImage from "gatsby-background-image"
import { useStaticQuery, graphql } from "gatsby"
import SEO from "../components/Seo";

const DataPage = () => {
  const imageData = useStaticQuery(graphql`
    query {
      fileName: file(relativePath: { eq: "overlay-dark-api-section.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1600) {
            ...GatsbyImageSharpFluid_withWebp_noBase64

          }
        }
      }
    }
  `)

  return (
    <Box>

      <SEO
        title={'Open API'}
        image={'/images/open-graph-image-purple.png'}
        favicon={'/images/svg/virus-purple-ico.svg'}
        keywords={`open API`}
      />

      <BackgroundImage
            Tag="section"
            fluid={imageData.fileName.childImageSharp.fluid}
            style={{
              backgroundSize: "contain",
              backgroundPosition: "center 0%",
            }}
            css={`
              background-color: ${p => p.theme.color.brand06};
            `}
      >
        <MaxWidth
          css={`
             {
              @media screen and (max-width: 960x) {
                width: 100%;
                max-width: 100%;
              }
            }
          `}
        >
          <Box>
            <Text
              as="h1"
              textStyle="tera"
              color="text06"
              mt="2em"
              mb="xl"
              mx={["l", null, 0]}
            >
              Datos
            </Text>
            <Box
              boxShadow="0 1rem 2rem -0.4rem rgba(0, 0, 0, 0.75)"
              border="1px solid #dddee0"
              bg="primaryBackground"
              px={["m", "l", "xl", "xl", "xxl"]}
              pt={["l", null, "xl"]}
              pb={[0, "l", "xl"]}
            >
              <Text as="h2" textStyle="mega" color="brand06" mb="l">
                Repos
              </Text>
              <Text as="p" textStyle="xl" width="100%" maxWidth="800px" mb="l">
                Descárgate los datos directamente para crear tus propias
                pruebas. Los tienes disponibles tanto en formato .csv como en
                formato .json y los actualizamos diariamente
              </Text>

              <Inline>
                <ApiPageBtn link="https://github.com/Secuoyas-Experience/covid-19-es">
                  Clónatelo en <Github fill="text06" height="30px" ml="8px" />
                </ApiPageBtn>
                <ApiPageBtn link="https://bitbucket.org/secuoyas/covid-19-es-data/">
                  Clónatelo en{" "}
                  <Bitbucket fill="text06" height="20px" ml="8px" />
                </ApiPageBtn>
              </Inline>

              <Box my={["m","l","xl"]}>
                <ModDashedLine />
              </Box>

              <Text as="h2" textStyle="mega" color="brand06" mb="l">
                API
              </Text>
              <Text
                as="p"
                textStyle="xl"
                maxWidth="1200px"
                width="100%"
                mb={["m", "l","xl"]}
              >
                Hemos preparado una serie de endpoints para que puedas acceder a
                la información directamente desde tu aplicación o página web. La
                API permite todo tipo de consultas, y se organiza entorno al
                timeline para facilitar el consumo por gráficos y
                visualizaciones.
              </Text>

              <Flex alignItems="center" flexDirection="column" mb={["m", "l","xl"]}>
                <Text as="h4" textStyle="label-bold" caps mb="s">
                  Entry Point
                </Text>
                <Text
                  as="a"
                  target="_blank"
                  rel="noopener noreferrer"
                  fontSize={["20px", "32px", "48px"]}
                >
                  https://covid19.secuoyas.io/api/v1
                </Text>
              </Flex>

              <Paragraph as="p" textStyle="bodyText" my="l" color="text01">
                Nuestro objetivo es unificar los datos de las distintas
                administraciones, por lo que dependemos de los sistemas de
                información de las mismas. Nuestra máxima es exponer información
                de calidad, más que en cantidad, por lo que solo recogeremos
                información de fuentes oficiales y sobre la que tengamos la
                certitud de que se puede reconstruir.
              </Paragraph>

              <Paragraph as="p" textStyle="bodyText" my="l" color="text01">
                Los enpoints están organizados en función de los niveles
                administrativos. Durante esta crisis hemos visto muchos datos a
                nivel global, comparando efectos entre paises. Pero lo cierto es
                que una epidemia se gestiona mejor entendiendo las dinámicas a
                nivel micro.
              </Paragraph>

              <Paragraph
                as="p"
                textStyle="bodyText"
                mt="l"
                mb="xs"
                color="text01"
              >
                Las colecciones agrupan de forma natural los distintos niveles
                administrativos:
              </Paragraph>

              <Text
                as="ol"
                textStyle="bodyText"
                mb="l"
                ml="20px"
                css={`
                   {
                    list-style: decimal;
                  }
                `}
              >
                <Text as="li">España</Text>
                <Text as="li">Comunidades autónomas</Text>
                <Text as="li">Provincias</Text>
              </Text>

              <Inline>
                <ApiPageBtn link="https://coronavirus.secuoyas.com/api-docs/">
                  <AccessApi fill="text06" height="22px" mr="8px" />
                  Documentación de la API
                </ApiPageBtn>
              </Inline>

              <Box
                maxWidth="1000px"
                mx="auto"
                mt="xl"
                mb={[0, "l", "xl"]}
                bg="#dddee0"
                p={["l", "l", "xl", "xxl"]}
                ml={["-24px", 0]}
                mr={["-24px", 0]}
              >
                <Text as="h2" textStyle="m-semi" mb="s" caps>
                  Listado de las CCAA y las Provincias activas en la API
                </Text>
                <Paragraph as="p" textStyle="bodyText" mb="m" color="text01">
                  La tabla muestra un listado completo de la estructura
                  administrativa territorial en España. Debido al modelo de
                  ordenación español, se da la casuítica de tener varias
                  comunidades autónommas mono-provinciales. En dichos casos, los
                  datos a nivel CCAA y provincia son idénticos. Sin embrago, en
                  estos casos, dado que el código ISO para la CCAA no es el
                  mismo que para la provincia, los datos deben llamarse mediante
                  el código ISO adecuado para cada enpoint.
                </Paragraph>

                <Text textStyle="xl">ISO 3166-2:ES</Text>

                <ApiTable title="Nivel País" tableData={isoCountryCodes} />
                <ApiTable title="Nivel CCAA" tableData={isoCCAACodes} />
                <ApiTable
                  title="Nivel Provincia"
                  tableData={isoProvinceCodes}
                />
              </Box>
            </Box>
          </Box>
        </MaxWidth>
      </BackgroundImage>
    </Box>
  );
};

export default DataPage;

export const ApiPageBtn = ({ children, link }) => (
  <Text
    as="a"
    color="text06"
    textStyle="xl"
    bg="ui08"
    borderRadius="7px"
    py="12px"
    px={["s","m"]}
    textAlign="center"
    mr="xs"
    display="flex"
    mb="m"
    alignItems="center"
    href={link}
    target="_blank"
    rel="noopener noreferrer"
    css={`
       {
        transition: all 0.2s;
        &:hover {
          background-color: ${theme("color.brand06")};
        }
      }
    `}
  >
    {children}
  </Text>
);

const ApiTable = ({ title, tableData }) => {
  return (
    <Box
      as="table"
      my="l"
      width={1}
      css={`
         {
          border-collapse: collapse;
          border-spacing: 0;
        }
      `}
    >
      <thead>
        <Text
          as="tr"
          textStyle="label-bold"
          color="text06"
          bg="brand06"
          textAlign="left"
          caps
        >
          <Text as="th" colSpan="3" py="xs" px="s">
            {title}
          </Text>
        </Text>
        <Text as="tr" textStyle="table-row-bold-l" color="text02" bg="ui03">
          <TableHeadCell title="Iso Code" />
          <TableHeadCell title="Administración - subdivisión" />
          <TableHeadCell title="Disponible" textAlign="right" />
        </Text>
      </thead>
      <tbody>
        {tableData.map((row,idx) => (
          <Text
            key={idx}
            as="tr"
            bg="ui01"
            textStyle="table-row-l"
            css={`{
                            border: 1px solid #DDDEE0};
                        }`}
          >
            <Text as="td" px="s" py="xs">
              {row.code}
            </Text>
            <Text as="td" px="s" py="xs">
              {row.name}
            </Text>
            <Text as="td" px="s" py="xs" textAlign="right">
              {row.available ? <AvailableIcon /> : <NotAvailableIcon />}
            </Text>
          </Text>
        ))}
      </tbody>
    </Box>
  );
};

export const TableHeadCell = ({ title, textAlign = "left" }) => (
  <Text as="th" px="s" py="xs" bg="ui03" textAlign={textAlign}>
    {title}
  </Text>
);

export const AvailableIcon = (_) => (
  <Box
    width="15px"
    height="15px"
    position="relative"
    css={`
       {
        float: right;
        &:after {
          content: "";
          height: 15px;
          border-left: 3px solid #1d9c44;
          position: absolute;
          transform: rotate(40deg);
          left: 3px;
          top: 2px;
        }

        &:before {
          content: "";
          height: 8px;
          border-left: 3px solid #1d9c44;
          position: absolute;
          transform: rotate(-40deg);
          left: -4px;
          top: 10px;
        }
      }
    `}
  />
);

export const NotAvailableIcon = (_) => (
  <Box
    width="15px"
    height="15px"
    position="relative"
    css={`
       {
        float: right;

        &:after {
          content: "";
          height: 15px;
          border-left: 3px solid #f45614;
          position: absolute;
          transform: rotate(45deg);
          left: 0px;
          top: 5px;
        }

        &:before {
          content: "";
          height: 15px;
          border-left: 3px solid #f45614;
          position: absolute;
          transform: rotate(-45deg);
          left: 0px;
          top: 5px;
        }
      }
    `}
  />
);
