export const isoCountryCodes = [
    {
        code: "ES",
        name: "España",
        available: true
    }
]
export const isoCCAACodes = [
    {
        code: "ES-AN",
        name: "Andalucía",
        available: true
    },
    {
        code: "ES-AR",
        name: "Aragón",
        available: true
    },
    {
        code: "ES-AS",
        name: "Asturias",
        available: true
    },
    {
        code: "ES-IB",
        name: "Baleares",
        available: true
    },
    {
        code: "ES-CN",
        name: "Canarias",
        available: true
    },
    {
        code: "ES-CB",
        name: "Cantabria",
        available: true
    },
    {
        code: "ES-CL",
        name: "Castilla y León",
        available: true
    },
    {
        code: "ES-CM",
        name: "Castilla La Mancha",
        available: true
    },
    {
        code: "ES-CT",
        name: "Catalunya",
        available: true
    },
    {
        code: "ES-VC",
        name: "Comunidad Valenciana",
        available: true
    },
    {
        code: "ES-EX",
        name: "Extremadura",
        available: true
    },
    {
        code: "ES-GA",
        name: "Galicia",
        available: true
    },
    {
        code: "ES-MD",
        name: "Comunidad Madrid",
        available: true
    },
    {
        code: "ES-MC",
        name: "Murcia",
        available: true
    },
    {
        code: "ES-NC",
        name: "Navarra",
        available: true
    },
    {
        code: "ES-PV",
        name: "País Vasco",
        available: true
    },
    {
        code: "ES-RI",
        name: "La Rioja",
        available: true
    },
    {
        code: "ES-CE",
        name: "Ceuta",
        available: true
    },
    {
        code: "ES-ML",
        name: "Melilla",
        available: true
    }
]


export const isoProvinceCodes = [
    {
        code: "ES-C",
        name: "A Coruña",
        available: false
    },
    {
        code: "ES-VI",
        name: "Álava",
        available: false
    },
    {
        code: "ES-AB",
        name: "Albacete",
        available: true
    },
    {
        code: "ES-A",
        name: "Alicante",
        available: true
    },
    {
        code: "ES-AL",
        name: "Almeria",
        available: true
    },
    {
        code: "ES-O",
        name: "Asturias",
        available: true
    },
    {
        code: "ES-AV",
        name: "Ávila",
        available: true
    },
    {
        code: "ES-BA",
        name: "Badajoz",
        available: true

    },
    {
        code: "ES-PM",
        name: "Baleares",
        available: true
    },
    {
        code: "ES-B",
        name: "Barcelona",
        available: false
    },
    {
        code: "ES-BU",
        name: "Burgos",
        available: true
    },
    {
        code: "ES-CC",
        name: "Cáceres",
        available: true
    },
    {
        code: "ES-CA",
        name: "Cádiz",
        available: true
    },
    {
        code: "ES-S",
        name: "Cantabria",
        available: true
    },
    {
        code: "ES-CS",
        name: "Castellón",
        available: true
    },
    {
        code: "ES-CE",
        name: "Ceuta",
        available: true
    },
    {
        code: "ES-CR",
        name: "Ciudad Real",
        available: true
    },
    {
        code: "ES-CO",
        name: "Córdoba",
        available: true
    },
    {
        code: "ES-CU",
        name: "Cuenca",
        available: true
    },
    {
        code: "ES-GI",
        name: "Gerona",
        available: false
    },
    {
        code: "ES-SS",
        name: "Gipúzcoa",
        available: false
    },
    {
        code: "ES-GR",
        name: "Granada",
        available: true
    },
    {
        code: "ES-GU",
        name: "Guadalajara",
        available: true
    },
    {
        code: "ES-H",
        name: "Huelva",
        available: true
    },
    {
        code: "ES-HU",
        name: "Huesca",
        available: true
    },
    {
        code: "ES-J",
        name: "Jaén",
        available: true
    },
    {
        code: "ES-LO",
        name: "La Rioja",
        available: true
    },
    {
        code: "ES-LP",
        name: "Las Palmas",
        available: false

    },
    {
        code: "ES-LE",
        name: "León",
        available: true
    },
    {
        code: "ES-L",
        name: "Lleida",
        available: false
    },
    {
        code: "ES-LU",
        name: "Lugo",
        available: false
    },
    {
        code: "ES-M",
        name: "Madrid",
        available: true
    },
    {
        code: "ES-MA",
        name: "Málaga",
        available: true
    },
    {
        code: "ES-ML",
        name: "Melilla",
        available: true
    },
    {
        code: "ES-MU",
        name: "Murcia",
        available: true
    },
    {
        code: "ES-NA",
        name: "Navarra",
        available: true
    },
    {
        code: "ES-OR",
        name: "Orense",
        available: false
    },
    {
        code: "ES-P",
        name: "Palencia",
        available: true
    },
    {
        code: "ES-PO",
        name: "Pontevedra",
        available: false
    },
    {
        code: "ES-SA",
        name: "Salamanca",
        available: true
    },
    {
        code: "ES-TF",
        name: "Santa Cruz de Tenerife",
        available: false
    },
    {
        code: "ES-SE",
        name: "Segovia",
        available: true
    },
    {
        code: "ES-SE",
        name: "Sevilla",
        available: true
    },
    {
        code: "ES-SO",
        name: "Soria",
        available: true
    },
    {
        code: "ES-T",
        name: "Tarragona",
        available: false
    },
    {
        code: "ES-TE",
        name: "Teruel",
        available: true
    },
    {
        code: "ES-TO",
        name: "Toledo",
        available: true
    },
    {
        code: "ES-V",
        name: "Valencia",
        available: false
    },
    {
        code: "ES-VA",
        name: "Valladolid",
        available: true
    },
    {
        code: "ES-VI",
        name: "Vizcaya",
        available: false
    },
    {
        code: "ES-ZA",
        name: "Zamora",
        available: true
    },
    {
        code: "ES-Z",
        name: "Zaragoza",
        available: true
    }
]