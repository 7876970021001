import { Text, Box } from "@sqymagma/elements";
import React from "react";
import { theme } from "../../utils/themeGet";

export const Paragraph = ({
  children,
  as = "span",
  color = "text01",
  textStyle = "bodyText",
  ...props
}) => {
  return (
    <Text
      color={color}
      textStyle={textStyle}
      as={as}
      css={`
        strong {
          font-weight: 600;
        }
      `}
      {...props}
    >
      {children}
    </Text>
  );
};

export const StrongGold = ({ children }) => (
  <Text
    as="strong"
    className="strong-gold"
    css={`
      &.strong-gold {
        color: ${theme("color.brand04")};
      }
    `}
  >
    {children}
  </Text>
);

export const StrongWhite = ({ children }) => (
  <Text
    as="strong"
    className="strong-white"
    css={`
      &.strong-white {
        color: ${theme("color.text06")};
      }
    `}
  >
    {children}
  </Text>
);

export const BoxedText =  ({children, width="100%", opacityBg="1", ml, p=["m", "l", null, "m"]}) => (
  <Box  p={p} width={width} position="relative">
      <Paragraph
        zIndex="1"
        position="relative"
        as="p"
        textStyle="xl-semi"
        color="text06"
        width={[1, null, null, null, 10 / 12]}
        mb="0"
        ml={ml}
      >
        {children}
      </Paragraph>
      <Box position="absolute" width="100%" height="100%" top="0" left="0" bg="brand01" opacity={opacityBg}/>
  </Box>
);
